import { useState, useEffect, useCallback, useRef } from "react";
import { useSelectedOption } from "../../contexts/SelectedOptionContext";
import "./WelcomePage.css";

const WelcomePage = ({ isFocused, onMinimize, onExit, zIndex }) => {
  const { selectedOption, setSelectedOption, setShowShowcase } =
    useSelectedOption();
  const leftTriangle =
    process.env.PUBLIC_URL + "/icons/leftTriangleYellow30.png";
  const rightTriangle =
    process.env.PUBLIC_URL + "/icons/rightTriangleYellow30.png";
  const lightbulbOff = process.env.PUBLIC_URL + "/icons/lightbulbOff.png";
  const lightbulbOn = process.env.PUBLIC_URL + "/icons/lightbulbOn.png";
  const filledHeart = process.env.PUBLIC_URL + "/icons/filledHeart.png";
  const emptyFolder = process.env.PUBLIC_URL + "/icons/emptyFolder.png";
  const folder = process.env.PUBLIC_URL + "/icons/folderWithPaper.png";
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [text, setText] = useState("");
  const [secondText, setSecondText] = useState("");
  const [thirdText, setThirdText] = useState("");
  const [fourthText, setFourthText] = useState("");
  const [optionOne, setOptionOne] = useState("");
  const [optionTwo, setOptionTwo] = useState("");
  const [optionThree, setOptionThree] = useState("");
  const [optionFour, setOptionFour] = useState("");
  //   const [showCursor, setShowCursor] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [size, setSize] = useState({ width: 600, height: 400 });
  const [isResizing, setIsResizing] = useState(false);
  const [folderIcon, setFolderIcon] = useState(emptyFolder);
  const [lightbulbIcon, setLightbulbIcon] = useState(lightbulbOff);

  const iconRef = useRef(null);
  const audioContextRef = useRef(null);
  const mouseDownBufferRef = useRef(null);
  const mouseUpBufferRef = useRef(null);

  const options = ["AboutMe", "Projects", "MovieBlog", "Contact"];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const isMobile = windowWidth < 768;
    setIsMaximized(isMobile);
  }, [windowWidth]);

  const handleMouseDown = useCallback(
    (e) => {
      setIsDragging(true);
      setStartPosition({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    },
    [position.x, position.y]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );

        let newX = e.clientX - startPosition.x;
        let newY = e.clientY - startPosition.y;

        const commandLineRect = iconRef.current.getBoundingClientRect();

        const maxX = vw - 5 - commandLineRect.width;
        const maxY = vh - 35 - commandLineRect.height;

        newX = Math.min(Math.max(newX, 5), maxX);
        newY = Math.min(Math.max(newY, 5), maxY);

        setPosition({ x: newX, y: newY });
      }
    },
    [isDragging, startPosition.x, startPosition.y]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const preventDragHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  const stopPropagationHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const maximizeClick = useCallback(() => {
    setIsMaximized(!isMaximized);
  }, [isMaximized]);

  useEffect(() => {
    const handleResize = () => {
      setPosition({
        x: (window.innerWidth - 616) / 2, //change this when you change cmd width
        y: (window.innerHeight - 500) / 2, //change this when you change cmd height
      });
    };

    handleResize();
  }, []);

  const startResizing = useCallback((e) => {
    e.preventDefault();
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleResize = useCallback(
    (e) => {
      if (isResizing) {
        const newWidth = Math.max(
          350,
          e.clientX - iconRef.current.getBoundingClientRect().left
        );
        const newHeight = Math.max(
          365,
          e.clientY - iconRef.current.getBoundingClientRect().top
        );

        setSize({ width: newWidth, height: newHeight });
      }
    },
    [isResizing]
  );

  const handleOptionSelect = (option) => {
    if (selectedOption === option) {
      setShowShowcase(true);

      setTimeout(() => {
        setShowShowcase(false);
      }, 100);
    } else {
      setSelectedOption(option);
    }
  };

  const handleMouseLightbulbEnter = () => {
    setTimeout(() => {
      setLightbulbIcon(lightbulbOn);
    }, 500);
  };

  const handleMouseLightbulbLeave = () => {
    setLightbulbIcon(lightbulbOff);
  };

  const handleMouseFolderEnter = () => {
    setTimeout(() => {
      setFolderIcon(folder);
    }, 500);
  };

  const handleMouseFolderLeave = () => {
    setFolderIcon(emptyFolder);
  };

  useEffect(() => {
    const handleMouseUp = () => stopResizing();
    const handleMouseMove = (e) => handleResize(e);

    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleResize, stopResizing]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const initialText = "Hello, World!\n\nI'm...\n";
    const thirdTextContent = "Matia Lee";
    const fourthTextContent = "Engineer, developer, lifelong learner.";
    let index = -1;
    let fourthIndex = -1;

    const typeText = () => {
      index++;
      if (index < initialText.length) {
        if (index <= initialText.indexOf("\n\n")) {
          setText((prev) => prev + initialText.charAt(index));
        } else if (
          index > initialText.indexOf("\n\n") &&
          index < initialText.lastIndexOf("\n")
        ) {
          const secondIndex = index - initialText.indexOf("\n\n") - 2;
          setSecondText(
            (prev) =>
              prev + (secondIndex === -1 ? "" : initialText.charAt(index))
          );
        }
      } else if (index === initialText.length) {
        setThirdText(thirdTextContent);
        setTimeout(() => typeFourthText(), 900);
      }
    };

    const typeFourthText = () => {
      fourthIndex++;
      if (fourthIndex < fourthTextContent.length) {
        setFourthText((prev) => prev + fourthTextContent.charAt(fourthIndex));
        setTimeout(typeFourthText, 70); // for typing speed
      } else {
        setSelectedOption("AboutMe");
        setOptionOne("About Me");
        setOptionTwo("Projects");
        setOptionThree("Movie Blog");
        setOptionFour("Contact");
      }
    };
    const timerId = setInterval(typeText, 70); // for typing speed

    return () => {
      clearInterval(timerId);
    };
  }, [setSelectedOption]);

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       setShowCursor((show) => !show);
  //     }, 500);

  //     return () => clearInterval(intervalId);
  //   }, []);

  useEffect(() => {
    const handleArrowDown = (event) => {
      if (!isFocused) return;

      let currentIndex = options.indexOf(selectedOption);
      if (event.key === "ArrowRight") {
        const nextIndex = (currentIndex + 1) % options.length;
        setSelectedOption(options[nextIndex]);
      } else if (event.key === "ArrowLeft") {
        const prevIndex = (currentIndex - 1 + options.length) % options.length;
        setSelectedOption(options[prevIndex]);
      }
    };

    if (isFocused) {
      window.addEventListener("keydown", handleArrowDown);
    }
    return () => {
      window.removeEventListener("keydown", handleArrowDown);
    };
  }, [selectedOption, setSelectedOption, isFocused]);

  const initializeAudio = async () => {
    if (audioContextRef.current === null) {
      audioContextRef.current = new (window.AudioContext ||
        window.AudioContext)();
    }

    const audioContext = audioContextRef.current;

    const loadSound = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return audioContext.decodeAudioData(arrayBuffer);
    };

    mouseDownBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mousedown.mp3`
    );
    mouseUpBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mouseup.mp3`
    );
  };

  const playSound = (audioBuffer) => {
    if (audioContextRef.current && audioBuffer) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
    }
  };

  useEffect(() => {
    initializeAudio();

    return () => {
      audioContextRef.current?.close();
    };
  }, []);

  const handleMouseSoundDown = (e) => {
    stopPropagationHandler(e);
    playSound(mouseDownBufferRef.current);
  };

  const handleMouseMinimize = (e) => {
    if (windowWidth >= 768) {
      stopPropagationHandler(e);
      playSound(mouseUpBufferRef.current);
      onMinimize();
    }
  };

  const handleMouseMaximize = (e) => {
    if (windowWidth >= 768) {
      stopPropagationHandler(e);
      playSound(mouseUpBufferRef.current);
      maximizeClick();
    }
  };

  const handleMouseExit = (e) => {
    if (windowWidth >= 768) {
      stopPropagationHandler(e);
      playSound(mouseUpBufferRef.current);
      onExit();
    }
  };

  const commandLineStyle = isMaximized
    ? {
        position: "fixed",
        width: "calc(100vw - 10px)",
        height: "calc(100vh - 41px)",
        top: "5px",
        left: "5px",
        right: "5px",
        bottom: "36px",
        margin: 0,
        padding: 0,
        zIndex: zIndex,
      }
    : {
        left: `${position.x}px`,
        top: `${position.y}px`,
        position: "absolute",
        width: `${size.width}px`,
        height: `${size.height}px`,
        zIndex: zIndex,
      };

  return (
    <div className="command-line" style={commandLineStyle} ref={iconRef}>
      <div
        className="header"
        onMouseDown={handleMouseDown}
        onDoubleClick={handleMouseMaximize}
      >
        <span className="title">C:\WELCOME\cmd.exe</span>
        <div className="icons">
          <img
            className="minimize-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAKElEQVQYlWP8//8/AymAiSTVI1UDC4zByMiINXz////PSJENjKRGHAAvZgoPiLbuwwAAAABJRU5ErkJggg=="
            alt="minimize"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseMinimize}
          />
          <img
            className="maximize-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOUlEQVQYld2RMQoAIAzEcuL/vxw3kSKoq5lzbelFJYkcUAPQTmKl1wk71gueN/wQmF+66QIgeuVNBqtLDxPoqi0wAAAAAElFTkSuQmCC"
            alt="maximize"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseMaximize}
          />
          <img
            className="exit-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAATUlEQVQYlZ2RQQ7AIAzD4v3/z+HEVLogwXpC1FZTwLZu6pkHwMDH7vevYJsJVLj2JIkeqU+p8DIhAR2OQoq0FWrmtNMipAXjQ/z+h9MaAucxEWL07JoAAAAASUVORK5CYII="
            alt="exit"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseExit}
          />
        </div>
      </div>
      <pre className="text">
        {text}
        {secondText.length > 0 && "\n"}
        {secondText}
        {thirdText.length > 0 && (
          <div className={isMaximized ? "name-maximized" : "name"}>
            {thirdText}
          </div>
        )}
        <div className="fourth-text-container">
          {fourthText.length > 0 && (
            <div
              className={isMaximized ? "fourth-text-maximized" : "fourth-text"}
            >
              {fourthText}
            </div>
          )}
        </div>
        <div className="options-container">
          <span>
            <img
              src={leftTriangle}
              alt="Left Selector"
              className={selectedOption === "AboutMe" ? "visible" : "hidden"}
            />
            <p
              onClick={() => {
                handleOptionSelect("AboutMe");
              }}
              className={selectedOption === "AboutMe" ? "option-selected" : ""}
            >
              {optionOne}
            </p>
            <img
              src={rightTriangle}
              alt="Right Selector"
              className={selectedOption === "AboutMe" ? "visible" : "hidden"}
            />
            <img className="heart" src={filledHeart} alt="heart icon" />
          </span>
          <span>
            <img
              src={leftTriangle}
              alt="Left Selector"
              className={selectedOption === "Projects" ? "visible" : "hidden"}
            />
            <p
              onMouseEnter={handleMouseLightbulbEnter}
              onMouseLeave={handleMouseLightbulbLeave}
              onClick={() => {
                handleOptionSelect("Projects");
              }}
              className={selectedOption === "Projects" ? "option-selected" : ""}
            >
              {optionTwo}
            </p>
            <img
              src={rightTriangle}
              alt="Right Selector"
              className={selectedOption === "Projects" ? "visible" : "hidden"}
            />
            <img
              className="lightbulb"
              src={lightbulbIcon}
              alt="lightbulb icon"
            />
          </span>
          <span>
            <img
              src={leftTriangle}
              alt="Left Selector"
              className={selectedOption === "MovieBlog" ? "visible" : "hidden"}
            />
            <p
              onClick={() => {
                handleOptionSelect("MovieBlog");
              }}
              className={
                selectedOption === "MovieBlog" ? "option-selected" : ""
              }
            >
              {optionThree}
            </p>
            <img
              src={rightTriangle}
              alt="Right Selector"
              className={selectedOption === "MovieBlog" ? "visible" : "hidden"}
            />
            <img
              className="cd"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAABo0lEQVRIiaWWSW7EIBBFP62+FCx9LVgW1/ISjkUWUJhidJIvtRCN4VGjrfBCRJR26845tVtPKeH7BuCsRcJzVowBAGC0zs8B6QScglrAG13XJfdNgAOIiJKzrszegWbAHvZZQwA07lKYhynEOAX2ca0gIkrXdSEU//9HWusBJizi4ErYNqGmkBgjdDlLgLLL7AY2V++2FhLLGlv1aTfxxhF2tqqH3PddkwMAFMeml9G6AZt8O0/iGd63grTjsmBDjBUWYsB933DOiWeICNbaIwRYFGwPI+/hnENKMsWVyi5l2AzC42cG6GEnnSBASQaerMY34nitxg9PTjfayXQ1M1O1aAWx1nKVQyklfkSE3E3iEfbdWdRmFJCzrBX3xRCDyNKZVDkg7SBx6ACmbE58RC3up9jznlIWqmbdW8ioVABGAIzWwp21t3BPegORFoljhGWl/hTQde/fWTJaNbOMVUHOOeW9/wNklNGmxob/Ey2oLEyb7EwJqnHfkxjkafhumPZ/jtcKyDHKBzxuI+/bCwttXzQrYA/aAV6BeuBKpw9IAPgBq0Fw2jrRDYcAAAAASUVORK5CYII="
              alt="cd"
            />
          </span>
          <span>
            <img
              src={leftTriangle}
              alt="Left Selector"
              className={selectedOption === "Contact" ? "visible" : "hidden"}
            />
            <p
              onMouseEnter={handleMouseFolderEnter}
              onMouseLeave={handleMouseFolderLeave}
              onClick={() => {
                handleOptionSelect("Contact");
              }}
              className={selectedOption === "Contact" ? "option-selected" : ""}
            >
              {optionFour}
            </p>
            <img
              src={rightTriangle}
              alt="Right Selector"
              className={selectedOption === "Contact" ? "visible" : "hidden"}
            />
            <img
              className="empty-folder"
              src={folderIcon}
              alt="lightbulb icon"
            />
          </span>
        </div>
        {/* <span className={`cursor ${showCursor ? "blink" : ""}`}>_</span> */}
      </pre>
      <div className="footer">
        <div className="footer-label">
          <p>© Copyright 2024 Matia Lee</p>
          <img
            onDragStart={preventDragHandler}
            onMouseDown={startResizing}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUElEQVQokZXPqxUAIAxD0ZSREQgGQCBYOSg4/Fuib8QTkvgY3Q9OucB6YMoFAEyHjmPwoh0mDKVhw6+GI741XPGp4YnXBhWPDSbcGsw4Bi8V/BZRwMk/E5EAAAAASUVORK5CYII="
            alt="resize icon"
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
