import "./Projects.css";
import { useEffect, useRef } from "react";

const Projects = ({ selectedProject, clearSelection }) => {
  const stampeddemo = process.env.PUBLIC_URL + "/images/stamped.mp4";
  const matialeedemo = process.env.PUBLIC_URL + "/images/matialeedemo.mp4";
  const letterstatsdemo =
    process.env.PUBLIC_URL + "/images/letterstatsdemo.mp4";
  const outfitlydemo = process.env.PUBLIC_URL + "/images/outfitlydemo.mp4";
  const fitcheck = process.env.PUBLIC_URL + "/images/fitcheck.png";
  const cinebergdemo = process.env.PUBLIC_URL + "/images/cinebergdemo.mp4";
  const pomodorodemo = process.env.PUBLIC_URL + "/images/pomodorodemo.mp4";
  const pattywagon = process.env.PUBLIC_URL + "/images/pattywagon.mp4";

  const projectRefs = {
    stamped: useRef(null),
    matialee: useRef(null),
    letterstats: useRef(null),
    outfitly: useRef(null),
    pomodoro: useRef(null),
    cineberg: useRef(null),
    pattywagon: useRef(null),
  };

  useEffect(() => {
    if (selectedProject && projectRefs[selectedProject]) {
      projectRefs[selectedProject].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      clearSelection();
    }
  }, [selectedProject, clearSelection]);

  return (
    <div className="projects-parent">
      <div className="projects">Projects</div>
      <div className="projects-content">
        <div className="projects-intro">
          <div className="projects-intro-content">
            Here are some of the projects that I've worked on (in reverse
            chronological order): <br /> You can read through my motivation and
            process for creating each project, but if you don’t care about that,
            you can find the{" "}
            {/* <span className="bold">techstack and features shown below</span> */}
            <span className="bold">features shown below</span>
          </div>
        </div>
        <div className="portfolio-project">
          <div className="matialee">
            <span className="subtitle" ref={projectRefs.stamped}>
              stamped
            </span>
            <div className="matialee-explanation">
              I created stamped with a group of friends during a design-a-thon.
              This was my first ever “a-thon,” and to be honest, I was never
              going to participate, but my friends needed a fourth member in
              their group and I had nothing better to do on that Saturday so I
              agreed. I’m not entirely sure what I expected, but I definitely
              didn’t expect to have as much as I did. Shoutout to David,
              Deekshu, and Eric for teaching me so much about the design process
              and for picking me up last minute!
              <div className="matialee-demo">
                <video
                  autoPlay
                  muted
                  loop
                  src={stampeddemo}
                  type="video/mp4"
                ></video>
              </div>
              So with the small timeframe of around 8 hours, we created stamped.
              The idea of stamped was simple: design a solution to foster
              community-building for small businesses, while also integrating
              emerging technologies. stamped is a mobile app that centres around
              users collecting stamps from the various small businesses that
              they visit and interact with. These stamps are used to fill a
              virtual passport. Check it out{" "}
              <a
                href="https://www.figma.com/proto/LyF7ae1n1EfrlcToaBiG8U/Stamped-Design-File?node-id=19-21102&t=HrwgpVVDq3KAFXYa-1&scaling=scale-down&content-scaling=fixed&page-id=2%3A14079&starting-point-node-id=19%3A21102"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              !
            </div>

            <div className="features">
              <div className="features-subtitle">Features</div>
              <ul>
                <li>
                  AI-generated feed of local businesses tailored towards your
                  profile
                </li>
                <li>
                  AI matching algorithm to determine large overlaps between
                  users’ interests and experiences
                </li>
                <li>
                  Challenge completion to receive stamps, fostering meaningful
                  interactions between business owners and customers
                </li>
                <li>
                  Provision of a digital platform for small business owners that
                  have not yet adapted new technologies
                </li>
                <li>
                  A tangible, interactive passport that gamifies the product and
                  drives user retention
                </li>
              </ul>
            </div>
          </div>
          <div className="matialee">
            <span className="subtitle" ref={projectRefs.matialee}>
              matialee.com
            </span>
            <div className="matialee-explanation">
              My portfolio – the one that you’re currently on is probably my
              favourite project that I’ve built so far. I’ve been wanting to
              make a personal portfolio for a long time now, wanting the
              opportunity to share my passions and personality with the world.
              And since it was a personal portfolio after all, I wanted to make
              it as custom and personal as possible.
              <div className="matialee-demo">
                <video
                  autoPlay
                  muted
                  loop
                  src={matialeedemo}
                  type="video/mp4"
                ></video>
              </div>
              As a proud 2000’s kid, I wanted to pay homage to the nostalgia of
              the technology that I grew up with and learned to interact with.
              What came to mind was the iconic windows xp “Bliss” wallpaper. So
              with that in mind, I created a website that to me felt as “retro”
              and tactile as possible.
            </div>
            {/* <div className="matialee-techstack">
              <div className="techstack-subtitle">Techstack/ Frameworks</div>
              <ul>
                <li>Javascript</li>
                <li>React</li>
                <li>Node</li>
                <li>Context API</li>
              </ul>
            </div> */}
          </div>
          <div className="letterstats">
            <span className="subtitle" ref={projectRefs.letterstats}>
              Letterstats
            </span>
            <div className="letterstats-explanation">
              I’ve been a huge fan of Letterboxd for a while now (@mat_lee on
              Letterboxd), and wanted a way to quickly view extensive stats. So,
              I created Letterstats.{" "}
              <div className="letterstats-demo">
                <video
                  autoPlay
                  muted
                  loop
                  src={letterstatsdemo}
                  type="video/mp4"
                ></video>
              </div>
              Letterstats, was equally a joy and a headache to make. Whether it
              was trying to make the graphs look right or trying to load in the
              dataframe correctly, or finally being able to see the aggregated
              data be presented correctly, I enjoyed each part of making it.{" "}
              <br />
              <b>
                You can try it for yourself{" "}
                <a
                  href="https://letterstats.streamlit.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                !
              </b>{" "}
              (If you don't have Letterboxd, feel free to try it with my
              account: mat_lee)
            </div>
            {/* <div className="matialee-techstack">
              <div className="techstack-subtitle">Techstack/ Libraries</div>
              <ul>
                <li>Python</li>
                <li>Streamlit</li>
                <li>Pandas</li>
                <li>NumPy</li>
                <li>Plotly</li>
                <li>Matplotlib</li>
                <li>NetworkX</li>
                <li>Community</li>
                <li>Itertools</li>
                <li>BeautifulSoup</li>
                <li>ThreadPoolExecutor</li>
                <li>Asyncio</li>
                <li>Aiohttp</li>
              </ul>
            </div> */}
            <div className="features">
              <div className="features-subtitle">Features</div>
              <ul>
                <li>
                  Comprehensive stats on the genres, cast, director, studio,
                  release year, and countries of the movies you watched based on
                  your Letterboxd profile
                </li>
              </ul>
            </div>
          </div>
          <div className="letterstats">
            <span className="subtitle" ref={projectRefs.outfitly}>
              Outfitly
            </span>
            <div className="letterstats-explanation">
              Before heading out, I’d always cook up an outfit in my head. Ten
              minutes before leaving my house, I’d put the fit on and realize
              that I in fact, did not cook as hard as I thought. I got tired of
              scrambling in my closet trying to find a good outfit and
              ultimately making a mess of my closet and room, so I created a
              virtual closet.{" "}
              <div className="letterstats-demo">
                <video
                  autoPlay
                  muted
                  loop
                  src={outfitlydemo}
                  type="video/mp4"
                ></video>
              </div>{" "}
              Fashion is something that is very very special and important to
              me. I’m a strong believer of the saying “Look good, feel good.”
              But extending from that, I think fashion is more than just looking
              good, it’s a way to express yourself and show the world who you
              are. Being able to throw on a fit, walk out the door, and feel
              confident is a feeling that nobody should be able to take away
              from you. This was the motivation and subsequently the birth of
              Outfitly: Outfits made simply. <br />
              <b>
                Check out a demo{" "}
                <a
                  href="https://www.loom.com/share/a28eafd513184557bddcf84c80d3082f?sid=00b1b270-8ee1-4d25-9390-237b8b66a5f6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                !
              </b>
            </div>
            <div className="credentials">
              <div className="credentials-text">
                And if you're wondering what my credentials for making a fasion
                app are:
              </div>
              <div className="credentials-image">
                <img src={fitcheck} alt="fitcheck" />
                <p>
                  {" "}
                  <i>
                    <b>Yes, I always had that shit on</b>
                  </i>
                </p>
              </div>
            </div>
            {/* <div className="outfitly-techstack">
              <div className="techstack-subtitle">
                Techstack/ Libraries/ Frameworks
              </div>
              <ul>
                <div className="frontend-techstack">Frontend</div>
                <ul>
                  <li>React</li>
                  <li>Node</li>
                  <li>Javascript</li>
                  <li>Typescript</li>
                  <li>React-router-dom</li>
                  <li>Context API</li>
                </ul>
                <div className="backend-techstack">Backend</div>
                <ul>
                  <li>Python</li>
                  <li>Pytorch (image processing)</li>
                  <li>Pillow</li>
                  <li>AWS - S3</li>
                  <li>Firebase</li>
                  <li>MySQL</li>
                  <li>SQLAlchemy</li>
                  <li>Flask</li>
                  <li>Numpy</li>
                </ul>
              </ul>
            </div> */}
            <div className="features">
              <div className="features-subtitle">Features</div>
              <ul>
                <li>Automatic background removal</li>
                <li>Complete login/ user functionality</li>
                <li>Custom fits</li>
                <li>Randomizable fits</li>
              </ul>
            </div>
          </div>
          <div className="letterstats">
            <span className="subtitle" ref={projectRefs.pomodoro}>
              Pomodoro
            </span>
            <div className="letterstats-explanation">
              Throughout my first term of University, there was no doubt a LOT
              of studying. But the one thing that helped my friends and I lock
              in and get our work done was a pomodoro app on our phones. We
              would compete with each other to see how many more hours we could
              study than the other, and by the end of the term, studying with
              pomodoro just became the norm. However, the urge to doom scroll or
              check my phone would still override the need to lock in. I needed
              a more permanent solution. One that allowed me to put my phone far
              away from me so I could sit at my desk and pomodoro. I created
              one.{" "}
              <div className="letterstats-demo">
                <video
                  autoPlay
                  muted
                  loop
                  src={pomodorodemo}
                  controls
                  type="video/mp4"
                ></video>
              </div>
              This was my first time working with Arduino and it was a blast.
              Learning how to turn on a simple LED with a button to building a
              customizable pomodoro, it was just as much challenging as it was
              rewarding. I’m also planning on 3-D printing a nice case to sit
              the pomodoro in so that it looks a little nicer sitting on my
              desk.
            </div>
            {/* <div className="cineberg-techstack">
              <div className="techstack-subtitle">
                Tools/ Techstack/ Libraries
              </div>
              <ul>
                <li>Arduino</li>
                <li>C++</li>
              </ul>
            </div> */}
            <div className="features">
              <div className="features-subtitle">Features</div>
              <ul>
                <li>Adjust brightness</li>
                <li>Custom study/ rest times</li>
              </ul>
            </div>
          </div>
          <div className="letterstats">
            <div className="subtitle" ref={projectRefs.cineberg}>
              Cineberg
            </div>
            <div className="letterstats-explanation">
              Okay I lied when I said that my portfolio was my favourite
              project. Cineberg might have that title. Cineberg is my first
              “real” fullstack software project. Even though it’s far far far
              from perfect, that to me is what makes it so special. I wanted to
              build something centered around movies, since that’s what I like.
              Originally, I started with building just a movie recommendation
              system. Having only experience in building simple weather apps or
              snake games, I thought it would be impossible to build a movie
              app, let alone incorporate machine learning into it. After many
              hours of error messages and not getting the correct outputs, I
              finally got it to how I wanted it. I then created a frontend to be
              able to display the recommender. But after each thing that I
              crossed off my to-do list, three more got added on. It started
              with just building a frontend to then adding updated movie news to
              ai movie analyses to many more other features.
              <div className="letterstats-demo">
                <video
                  autoPlay
                  muted
                  loop
                  src={cinebergdemo}
                  type="video/mp4"
                ></video>
              </div>{" "}
              Now, I could talk about this app for hours and hours, going over
              everything that I did wrong and everything I learned and the
              entire process, but for the sake of brevity: after big ups and
              downs, and many many hours of work, my creation was complete.{" "}
              <br />
              <b>
                You can check out a full demo{" "}
                <a
                  href="https://www.loom.com/share/de2e72280e08411bbebb1129b0c5c683?sid=4d505cdb-5786-4e62-8af7-b97125bb23fb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                !
              </b>
            </div>
            {/* <div className="cineberg-techstack">
              <div className="techstack-subtitle">
                Techstack/ Libraries/ Frameworks
              </div>
              <ul>
                <div className="frontend-techstack">Frontend</div>
                <ul>
                  <li>React</li>
                  <li>Node</li>
                  <li>Javascript</li>
                  <li>Typescript</li>
                  <li>React-router-dom</li>
                  <li>Context API</li>
                </ul>
                <div className="backend-techstack">Backend</div>
                <ul>
                  <li>Python</li>
                  <li>Vector space model for content based filtering</li>
                  <li>Numpy</li>
                  <li>Pandas</li>
                  <li>Celery</li>
                  <li>Selenium</li>
                  <li>Firebase</li>
                  <li>MySQL</li>
                  <li>SQLAlchemy</li>
                  <li>Flask</li>
                </ul>
              </ul>
            </div> */}
            <div className="features">
              <div className="features-subtitle">Features</div>
              <ul>
                <li>Individual movie information for 800,000+ movies</li>
                <li>ML movie recommendation system</li>
                <li>AI movie analyses</li>
                <li>Updated movie news feeds</li>
                <li>Complete user login functionality</li>
                <li>User specific movie recommendations</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="letterstats">
          <div className="subtitle" ref={projectRefs.pattywagon}>
            Patty Wagon
          </div>
          <div className="letterstats-explanation">
            The birth of the patty wagon started from a school assignment. The
            task was to create any vehicle. An important disclaimer for the
            assignment was that the effort expected was equivalent to an hour’s
            worth of work – basically, don’t waste your time making this
            perfect. So what did I do? I spent my entire weekend – probably
            around 10 hours worth of work creating my Magnum Opus.{" "}
            <div className="letterstats-demo">
              <video
                autoPlay
                muted
                loop
                src={pattywagon}
                controls
                type="video/mp4"
              ></video>
            </div>
            Again, paying homage to the early 2000s and my favourite TV show, I
            created the iconic Krabby Patty Wagon. Creating this was genuinely
            so much fun. I spent so many countless hours trying to make each
            detail perfect – like for example, I think just making the cheese
            look melted took a good 3 hours. In the end though, I created a
            one-to-one replica of the Patty Wagon. It’s also fully functional so
            printing it makes for a pretty neat desk toy.
          </div>
          {/* <div className="cineberg-techstack">
            <div className="techstack-subtitle">Tools</div>
            <ul>
              <li>Solidworks</li>
            </ul>
          </div> */}
          <div className="features">
            <div className="features-subtitle">Features</div>
            <ul>
              <li>6 individual components</li>
              <li>100% functional</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
