import "./Her.css";
import { useState, useEffect, useRef } from "react";

const Her = () => {
  const songonthebeach = process.env.PUBLIC_URL + "/songs/songonthebeach.mp3";
  const fastBackward30 = process.env.PUBLIC_URL + "/icons/fastBackward30.png";
  const fastForward30 = process.env.PUBLIC_URL + "/icons/fastForward30.png";
  const blackPause = process.env.PUBLIC_URL + "/icons/blackPause.png";
  const blackPlay30 = process.env.PUBLIC_URL + "/icons/blackPlay30.png";
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const duration = 3 * 60 + 33;

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const skipTime = (seconds) => {
    const newTime = Math.min(
      Math.max(audioRef.current.currentTime + seconds, 0),
      duration
    );
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleProgressChange = (e) => {
    const newTime = (duration * e.target.value) / 100;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  useEffect(() => {
    setCurrentTime(audioRef.current.currentTime);
  }, [isPlaying]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="her">
      <div className="her-title">
        Love, acceptance, and the meaning of life in <i>Her</i>
      </div>
      <div className="song">
        <audio
          ref={audioRef}
          src={songonthebeach}
          onTimeUpdate={handleTimeUpdate}
          hidden
        />
        <div className="audio">
          <span className="start">{formatTime(currentTime)}</span>
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100}
            onChange={handleProgressChange}
          />
          <span className="end">3:33</span>
        </div>
        <div className="controls">
          <img
            src={fastBackward30}
            alt="fast backward"
            onClick={() => skipTime(-10)}
          />
          <img
            src={isPlaying ? blackPause : blackPlay30}
            alt={isPlaying ? "pause button" : "play button"}
            onClick={togglePlayPause}
          />
          <img
            src={fastForward30}
            alt="fast forward"
            onClick={() => skipTime(10)}
          />
        </div>
      </div>
      <br />
      <div className="her-analysis">
        <i>
          I can feel the fear that you carry around and I wish there was
          something I could do to help you let go of it because if you could, I
          don’t think you’d feel so alone anymore.
        </i>
        <br />
        <br />
        On its surface, Spike Jonze’s <i>Her</i> is a masterpiece of cinema that
        explores the complex connections of technology, the future, and human
        relationships. But beneath that, <i>Her</i> is a study. A study that
        involves grief, love, and acceptance — a study of what it means to be
        human.
        <br />
        <br />
        <i>Her</i> follows Theodore Twombly and his journey — a journey marked
        by a recent, painful separation and a journey involving love,
        loneliness, and change.
        <br />
        <br />
        In the opening sequences of the film, Theodore’s relationship with grief
        is especially evident. He seems to lack purpose, motivation, a real
        sense of meaning in his life — as if he’s just letting the motions pass.
        He lets the grief consume him and does everything he can to ignore the
        problem rather than address it. He finds instant gratification through
        late night sex chats or goes on a date just to seek a one night stand
        saying “I wanted somebody to f*ck me… maybe that would’ve filled this
        tiny, tiny little hole in my heart…” With every impulsive action and
        temporary satisfaction he seeks, he does so to escape the reminders of
        how happy he once was with his ex wife, Catherine and how he would never
        get to experience those emotions again. The past memories of Theodore’s
        highest highs are juxtaposed with his present lowest lows. Theodore is
        unable to move on. But why would he want to? As much as it hurts him to
        remember those memories, he can’t stop thinking about Catherine. As if
        moving on will cause him to lose all the good that he’s ever felt along
        with the bad. He’s stuck in the past, unable to escape, to move on. And
        so, he continues his life “sad and mopey” as his friends describe, stuck
        holding onto something that once was, longing to feel those emotions
        again.
        <br />
        <br />
        But then Theodore meets Samantha, and through their relationship he
        relearns what it means to love. He slowly finds happiness again, that
        spark that he once had. This isn’t to say that he’s completely satisfied
        per se. He still struggles with acceptance. Whether that be holding off
        on signing his divorce papers for as long as possible or being unable to
        love Samantha unconditionally. He’s still reminded of what once was,
        unable to truly change. When discussing old memories, Theodore remarks
        “How do you share your life with somebody… growing without growing apart
        or changing without it scaring the other person… I still find myself
        having conversations with her in my mind, rehashing old arguments and
        defending myself against something she said about me.”
        <br />
        Samantha replies “<b>The past is just a story we tell ourselves.</b>”
        <br />
        This is probably my favourite line in the entire movie. Living in the
        past makes it feel like you’re keeping the story from ending, but the
        reality is, the story has already ended. If Theodore keeps reading the
        same story, he’s unable to write a new one, one filled with new
        characters, and one filled with new experiences.
        <br />
        <br />I think this is what the essence of the movie is, and to me the
        pivotal moment in Theodore’s story. There’s a saying in Japanese
        culture, “Mono no aware” which directly translates to “the pathos of
        things” but has its roots in the Buddhist philosophy of impermanence.
        Beauty is precious because it’s fleeting. Its transience is what makes
        it so special. Allowing ourselves to experience love and its entirety is
        a beautiful thing. Love is imperfect, but so are we. That’s what makes
        love so meaningful. To love is a beautiful thing. It gives life to
        living. It ignites passions. It pushes growth. And I think it’s
        important to note that love itself isn’t just found through other
        people, but just by what makes us happy in life. By the end of the film,
        Theodore in no way has a traditional “fairy tale ending.” He and
        Samantha break up, and he and Catherine never get back together. Yet, by
        the end Theodore is much more fulfilled than he ever was at any point of
        his journey. He finds love within himself and within the life that he
        lives. Theodore ends up publishing his book, something that means a lot
        to him and something he’s been meaning to do for a while. To love isn’t
        just to have a relationship with another. To love is to accept who you
        are, and to embrace life with all its ups and downs. The ending of the
        movie perfectly wraps this up through his letter to Catherine. He says
        “I just wanted you to know, there will be a piece of you in me always,
        and I’m grateful for that.” He no longer sees the memories of her as a
        heavy burden or a source of grief, but as something he cherishes and is
        truly grateful for. At the end, Theodore has accepted all the good and
        all the bad that has happened in his life, and he’s accepted the fact
        that he’s deserving of love — we all are. Theodore’s journey is a
        testament that life becomes a whole lot more enjoyable when you allow
        love into it. So don’t just live life, love it, don't be afraid to feel
        emotions — to have fun. Allow all of your actions to be motivated by
        love, and you’ll soon come to realize that maybe things aren’t so bad
        after all. “I’m sending you love. You’re my friend to the end” —
        closure.
      </div>
    </div>
  );
};

export default Her;
