import "./MissSunshine.css";

const MissSunshine = () => {
  return (
    <div className="miss-sunshine">
      <div className="coming-soon">Coming soon...</div>
    </div>
  );
};

export default MissSunshine;
