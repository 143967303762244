import "./Snake.css";
import { useState, useEffect, useCallback, useRef, useReducer } from "react";

const Snake = ({ isFocused, onExit }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [snake, setSnake] = useState([{ x: 8, y: 8 }]);
  const [startGame, setStartGame] = useState(false);
  const [startContainer, setStartContainer] = useState(true);
  const [gameOverContainer, setGameOverContainer] = useState(false);

  const snakeIcon = process.env.PUBLIC_URL + "/icons/snakelogo.png";
  const startsnake = process.env.PUBLIC_URL + "/images/startsnake.png";

  const iconRef = useRef(null);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const mouseDownBufferRef = useRef(null);
  const mouseUpBufferRef = useRef(null);

  const directionReducer = (state, action) => {
    switch (action.type) {
      case "ArrowUp":
        return state.y === 0 ? { x: 0, y: -1 } : state;
      case "ArrowDown":
        return state.y === 0 ? { x: 0, y: 1 } : state;
      case "ArrowLeft":
        return state.x === 0 ? { x: -1, y: 0 } : state;
      case "ArrowRight":
        return state.x === 0 ? { x: 1, y: 0 } : state;
      case "RESET_DIRECTION":
        return { x: 0, y: 0 };
      default:
        return state;
    }
  };

  const [direction, dispatch] = useReducer(directionReducer, { x: 0, y: 0 });

  const handleMouseDown = useCallback(
    (e) => {
      setIsDragging(true);
      setStartPosition({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    },
    [position.x, position.y]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );

        let newX = e.clientX - startPosition.x;
        let newY = e.clientY - startPosition.y;

        const commandLineRect = iconRef.current.getBoundingClientRect();

        const maxX = vw - commandLineRect.width;
        const maxY = vh - commandLineRect.height;

        newX = Math.min(Math.max(newX, 0), maxX);
        newY = Math.min(Math.max(newY, 0), maxY);

        setPosition({ x: newX, y: newY });
      }
    },
    [isDragging, startPosition.x, startPosition.y]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const preventDragHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  const stopPropagationHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const foodSpawn = () => {
    let newFoodPosition;
    do {
      newFoodPosition = {
        x: Math.floor(Math.random() * 17),
        y: Math.floor(Math.random() * 17),
      };
    } while (isPositionOccupiedBySnake(newFoodPosition));
    return newFoodPosition;
  };

  const isPositionOccupiedBySnake = (position) => {
    return snake.some(
      (segment) => segment.x === position.x && segment.y === position.y
    );
  };

  const [food, setFood] = useState(foodSpawn());

  const updateGame = useCallback(() => {
    if (
      !startGame ||
      !canvasRef.current ||
      (direction.x === 0 && direction.y === 0)
    )
      return;

    setSnake((prevSnake) => {
      let newHead = {
        x: prevSnake[0].x + direction.x,
        y: prevSnake[0].y + direction.y,
      };

      if (
        newHead.x < 0 ||
        newHead.x >= 17 ||
        newHead.y < 0 ||
        newHead.y >= 17
      ) {
        setGameOverContainer(true);
        dispatch({ type: "RESET_DIRECTION" });
        return [{ x: 8, y: 8 }];
      }

      for (let segment of prevSnake) {
        if (segment.x === newHead.x && segment.y === newHead.y) {
          setGameOverContainer(true);
          dispatch({ type: "RESET_DIRECTION" });
          return [{ x: 8, y: 8 }];
        }
      }

      let newSnake = [newHead, ...prevSnake];

      if (newHead.x === food.x && newHead.y === food.y) {
        setFood(foodSpawn());
      } else {
        newSnake.pop();
      }

      return newSnake;
    });
  }, [direction, food, startGame]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");

    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    ctx.fillStyle = "red";
    ctx.fillRect(food.x * 35, food.y * 35 + 24, 35, 35);

    snake.forEach((segment, index) => {
      ctx.fillStyle = index === 0 ? "darkgreen" : "green";
      ctx.fillRect(segment.x * 35, segment.y * 35 + 24, 35, 35);
    });
  }, [snake, food]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (gameOverContainer) {
        return;
      }

      if (
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)
      ) {
        dispatch({ type: event.key });
      }
    };

    if (isFocused) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isFocused, gameOverContainer]);

  useEffect(() => {
    const gameInterval = setInterval(updateGame, 160);

    return () => clearInterval(gameInterval);
  }, [updateGame]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const handleResize = () => {
      setPosition({
        x: (window.innerWidth - 595) / 2, //change this when you change cmd width
        y: (window.innerHeight - 665) / 2, //change this when you change cmd height
      });
    };

    handleResize();
  }, []);

  const handleStartGame = () => {
    setStartGame(true);
    setStartContainer(false);
  };

  const handleRetryGame = () => {
    setGameOverContainer(false);
    setStartGame(true);
    setSnake([{ x: 8, y: 8 }]);
    setFood(foodSpawn());
    dispatch({ type: "RESET_DIRECTION" });
  };

  const initializeAudio = async () => {
    if (audioContextRef.current === null) {
      audioContextRef.current = new (window.AudioContext ||
        window.AudioContext)();
    }

    const audioContext = audioContextRef.current;

    const loadSound = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return audioContext.decodeAudioData(arrayBuffer);
    };

    mouseDownBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mousedown.mp3`
    );
    mouseUpBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mouseup.mp3`
    );
  };

  const playSound = (audioBuffer) => {
    if (audioContextRef.current && audioBuffer) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
    }
  };

  useEffect(() => {
    initializeAudio();

    return () => {
      audioContextRef.current?.close();
    };
  }, []);

  const handleMouseSoundDown = (e) => {
    stopPropagationHandler(e);
    playSound(mouseDownBufferRef.current);
  };

  const handleMouseSoundUp = (e) => {
    stopPropagationHandler(e);
    playSound(mouseUpBufferRef.current);
    onExit();
  };

  return (
    <div
      className="snake"
      style={{
        position: "absolute",
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: "595px",
        height: "619px",
      }}
      ref={iconRef}
    >
      <div className="snake-header" onMouseDown={handleMouseDown}>
        <div className="header-labels">
          <img
            className="snake-icon-tab"
            src={snakeIcon}
            alt="internet explorer"
          />
          <span className="snake-title">Snake</span>
          <div className="border-extension"></div>
        </div>
        <div className="icons">
          <img
            className="showcase-exit-icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAATUlEQVQYlZ2RQQ7AIAzD4v3/z+HEVLogwXpC1FZTwLZu6pkHwMDH7vevYJsJVLj2JIkeqU+p8DIhAR2OQoq0FWrmtNMipAXjQ/z+h9MaAucxEWL07JoAAAAASUVORK5CYII="
            alt="exit"
            onDragStart={preventDragHandler}
            onMouseDown={handleMouseSoundDown}
            onMouseUp={handleMouseSoundUp}
          />
        </div>
      </div>
      {startContainer && (
        <div className="start-game-container">
          <img src={startsnake} alt="start game" />
          <button onClick={() => handleStartGame()}>START</button>
        </div>
      )}
      <canvas
        ref={canvasRef}
        width="595px"
        height="619px"
        className="game-canvas"
      ></canvas>
      {gameOverContainer && (
        <div className="game-over-container">
          <div className="game-over">
            <p>
              GAME <br /> OVER!
            </p>
            <button onClick={handleRetryGame}>PLAY AGAIN</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Snake;
