import React, { createContext, useContext, useState } from "react";

const SelectedOptionContext = createContext();

export const useSelectedOption = () => useContext(SelectedOptionContext);

export const SelectedOptionProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showShowcase, setShowShowcase] = useState(false);

  const value = {
    selectedOption,
    setSelectedOption,
    showShowcase,
    setShowShowcase,
  };

  return (
    <SelectedOptionContext.Provider value={value}>
      {children}
    </SelectedOptionContext.Provider>
  );
};
