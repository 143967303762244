import "./AboutMe.css";

const AboutMe = ({ handleOptionChange }) => {
  const me = process.env.PUBLIC_URL + "/images/me.png";
  const astronautTraining =
    process.env.PUBLIC_URL + "/images/astronautTraining.png";
  const robotics = process.env.PUBLIC_URL + "/images/robotics.png";
  const bottleRocket = process.env.PUBLIC_URL + "/images/bottleRocket.png";
  const skating = process.env.PUBLIC_URL + "/images/skating.png";
  const skiing = process.env.PUBLIC_URL + "/images/skiing.png";
  const snowboarding = process.env.PUBLIC_URL + "/images/snowboarding.png";
  const bouldering = process.env.PUBLIC_URL + "/images/bouldering.png";
  const superhero = process.env.PUBLIC_URL + "/images/superhero.png";

  return (
    <div className="about-me-parent">
      <div className="about-me">About Me</div>
      <div className="about-me-content">
        <div className="intro">
          <div className="intro-content">
            👋 Hi there, my name is{" "}
            <span className="bold">
              {" "}
              <a
                href="https://www.linkedin.com/in/matialee/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Matia Lee
              </a>
            </span>{" "}
            and I'm a second year Systems Design Engineering student at the
            University of Waterloo! Below is a collection of everything that
            makes me, me. I really hope you enjoy reading through it! If you
            have any questions or would like to connect, please do not hesitate
            to reach out{" "}
            <span
              className="projects-hyperlink"
              onClick={() => handleOptionChange("Contact")}
            >
              here
            </span>
            !
          </div>
          <div className="self">
            <img src={me} alt="self portrait" />
            <p>Me!</p>
          </div>
        </div>
        <div className="seperator"></div>
        <div className="backstory">
          <div className="astronaut-picture">
            <img src={astronautTraining} alt="me as an astronaut" />
            <p>Astronaut training</p>
          </div>{" "}
          I never actually thought about studying engineering until probably
          around late highschool. Growing up, I was always told to dream big, so
          naturally I was inclined to become an astronaut. When I realized that
          going to space wasn’t as easy as I thought, I shifted my focus to
          medicine. This became an obsession. As a kid I watched videos on med
          students’ “day in the lives” or “study with me” and I vividly remember
          mapping my entire life around becoming a doctor. I had my heart set on
          studying the health sciences, becoming a doctor, and living happily
          ever after. Until in highschool I wrote my first real (non covid)
          biology unit test, and got a 40% and then I realized that I actually
          hated biology… I couldn’t help but laugh. A doctor that hated studying
          biology. This entire time, I thought I was fulfilling my destiny -
          acheiving my life's purpose. This forced me to really think about what
          I wanted to pursue. I thought about times when I was truly happy with
          what I was doing. I loved my grade 6 Lego robotics team, I loved
          building stuff, and I loved finding solutions. <br />
          <i>
            <b>Engineering just made sense.</b>
          </i>
        </div>
        <div className="robotics-picture">
          <div className="robotics-picture-container">
            <img src={robotics} alt="robotics action shot!" />
            <p>My first robotics competition</p>
          </div>
          <div className="bottle-rocket-container">
            <img src={bottleRocket} alt="bottle rocket" />
            <p>
              My grade 5 science fair bottle rocket project made with on old m&m
              tube, propelled by Alka-Seltzer and water
            </p>
          </div>
        </div>
        <div className="hobbies-container">
          <span className="subtitle">My Hobbies</span>
          <div className="hobbies-content">
            Apart from when I’m in school or when I’m working, I’d say my time
            is distributed towards three main things:
            <div className="passion-projects">
              <span>My passion projects</span>
              <div className="passion-projects-content">
                I’m always looking for new things to learn, new projects to
                build, new ways to “deepen my bag.” When I embark on building a
                project, I try to build something as personal as I can – making
                sure it’s something that I’m passionate about. I figure if I’m
                going to be investing hours into building something, why not
                just make it an extension of who I am. This has allowed me to
                create some things I genuinely care about and am genuinely
                interested in. What I especially love about my projects though
                is how I can actually see improvement from one project to the
                next. Whether that be better design judgements, a deeper
                understanding of what I’m doing, or simply just cleaner, more
                readable code. A more detailed insight of what I’ve done and my
                motivation behind creating them can be found on{" "}
                <span
                  className="projects-hyperlink"
                  onClick={() => handleOptionChange("Projects")}
                >
                  my projects
                </span>{" "}
                tab!
              </div>
            </div>
            <div className="sports">
              <span>Sports</span>
              <div className="sports-content">
                Standing at 5'10" and weighing 130 lbs through most of my
                mid-teens, I stood on the sidelines of the athletic world, my
                only real involvement being a third-grade house league soccer
                team (I was on the blue team btw). But, this distance from
                formal sports participation masked a deeper passion, one stifled
                by a fear of failure and judgment rather than a disinterest in
                sports.
                <div className="skating-picture">
                  <img src={skating} alt="skating" />
                  <p>Me, age 8</p>
                </div>
                <br />
                Thinking back, at heart, some of my fondest memories revolved
                around sports – especially winter sports. Those memories
                involved racing around skating rinks as a kid, sending black
                diamonds on skis while doing the pizza, to eventually carving
                s-curves on a snowboard – showing a gradual shift from
                intimidation to exhilaration. Informal sports, like biking off
                into the sunset with friends after school and getting cooked in
                pickup ball, served as playgrounds forjoy and personal
                challenge, transforming sports from arenas of judgment to
                sources of freedom. Some things never change (I’m still getting
                cooked in pickup ball but now in the SJU court). Today, this
                love has turned into a disciplined commitment to lifting (I try
                to go around 5-6 times a week) and a recent discovery and love
                for bouldering, marking a full circle from avoidance to passion.
                Now, I feel like my best self, and am excited to look back this
                time next year to see how much I’ve grown (physically and
                mentally!). Through this journey, sports have become not just
                leisure but a testament to resilience, self-acceptance, and the
                enduring power of personal growth, illustrating that it's never
                too late to embrace your passions and push yourself beyond
                perceived limits.
                <div className="sports-pictures">
                  <div className="skiing-picture">
                    <img src={skiing} alt="skiing" />
                    <p>Me, also age 8</p>
                  </div>
                  <div className="snowboarding-picture">
                    <img src={snowboarding} alt="snowboarding" />
                    <p>Me, age 16</p>
                  </div>
                  <div className="bouldering-picture">
                    <img src={bouldering} alt="bouldering" />
                    <p>
                      Me, age 12 first time ever bouldering (this was probably
                      an 8C+ flash)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="movies">
              <span>Film</span>
              <div className="movies-content">
                My obsession for movies – or <i>films</i> is deep rooted in
                childhood nostalgia and superheroes. Of course, as a man with
                class, my favourite movie was Spider-Man 2. My mom bought me a
                cd of the movie (which I still have), and it would be the only
                movie that I watched. I remember running around the house making
                “thwipping” noises, climbing on the couch pretending to fight
                Doc-Ock. This love for movies continued. I specifically remember
                each summer blockbuster, my dad and I would reserve tickets for
                whichever movie was releasing at the time. It became tradition.
                We went from watching the first phase of the MCU movies to
                watching the disappointment of the multiverse saga. But still,
                it was the act of watching movies and spending time with family
                that I cherished above all. Also, as I grew up and watched a lot
                more different genres and types of movies, I fell in love with
                the artistry of films. Each movie I watched, I gained a deeper
                appreciation for the story or message that the directors and
                writers were trying to tell. I fell in love with the aesthetics
                of cinematography, I fell in love with the “holy shit” feeling
                after watching a really good movie, and of course I fell in love
                with putting my friends on to good movies and being able to
                share that feeling and spark communication. Hopefully one day in
                the distant future, I can direct my own short film! Right now,
                my favourite director is Wes Anderson and my favourite studio is
                A24. You can look through a couple of my favourite movies in the{" "}
                <span
                  className="projects-hyperlink"
                  onClick={() => handleOptionChange("MovieBlog")}
                >
                  movies tab
                </span>
                , and read my two cents on them.
              </div>
              <div className="superhero-image">
                <img src={superhero} alt="superhero" />
                <p>
                  This might have been where my love for superheroes began 🤔
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
