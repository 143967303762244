import "./Fox.css";
import { useState, useEffect, useRef } from "react";

const Fox = () => {
  const kristoffersontheme =
    process.env.PUBLIC_URL + "/songs/kristofferson.mp3";
  const wolfscene = process.env.PUBLIC_URL + "/images/mrfox.mp4";
  const fastBackward30 = process.env.PUBLIC_URL + "/icons/fastBackward30.png";
  const fastForward30 = process.env.PUBLIC_URL + "/icons/fastForward30.png";
  const blackPause = process.env.PUBLIC_URL + "/icons/blackPause.png";
  const blackPlay30 = process.env.PUBLIC_URL + "/icons/blackPlay30.png";
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const duration = 1 * 60 + 35;

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const skipTime = (seconds) => {
    const newTime = Math.min(
      Math.max(audioRef.current.currentTime + seconds, 0),
      duration
    );
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleProgressChange = (e) => {
    const newTime = (duration * e.target.value) / 100;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  useEffect(() => {
    setCurrentTime(audioRef.current.currentTime);
  }, [isPlaying]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="fox">
      <div className="fox-title">
        <i>Fantastic Mr. Fox</i> :&nbsp; How to be FANTASTIC
      </div>
      <div className="song">
        <audio
          ref={audioRef}
          src={kristoffersontheme}
          onTimeUpdate={handleTimeUpdate}
          hidden
        />
        <div className="audio">
          <span className="start">{formatTime(currentTime)}</span>
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100}
            onChange={handleProgressChange}
          />
          <span className="end">1:35</span>
        </div>
        <div className="controls">
          <img
            src={fastBackward30}
            alt="fast backward"
            onClick={() => skipTime(-10)}
          />
          <img
            src={isPlaying ? blackPause : blackPlay30}
            alt={isPlaying ? "pause button" : "play button"}
            onClick={togglePlayPause}
          />
          <img
            src={fastForward30}
            alt="fast forward"
            onClick={() => skipTime(10)}
          />
        </div>
      </div>
      <br />
      <div className="fox-analysis">
        <div className="scene">
          <video src={wolfscene} controls type="video/mp4"></video>
        </div>
        This is my favourite scene from the 2009 Wes Anderson movie,{" "}
        <i>Fantastic Mr. Fox</i>. I mean, don’t get me wrong, the entire movie
        is perfect — it’s my favourite movie of all time, but there’s always
        been something so special about this scene in particular. Part of why I
        think I like this scene so much is purely because of how silly it is.
        The silence from the wolf, the fist in the air, the tear in the eye —
        the entire scene is just brilliantly comical. I also like how the scene
        seems so random and out of place. Mr. Fox and team have just narrowly
        escaped their death and are rushing back to see their families but they
        stop along the road to admire this wolf. From these reasons alone, ever
        since I first watched the movie, this scene has been the most memorable.
        But it wasn’t until my third rewatch when I finally got to fully
        appreciate and understand the scene, and it wasn’t until my third
        rewatch when I realized that this scene is the most important and
        meaningful scene in the entire movie.
        <br />
        <br />
        Anderson’s Fantastic Mr. Fox is surprisingly real considering that it’s
        an adaptation of a children’s book. From the very beginning of the
        movie, it’s clear that Mr. Fox is experiencing a midlife crisis. One of
        the very first lines is the movie is Mr. Fox expressing his
        dissatisfaction saying, “I don’t want to live in a hole anymore it makes
        me feel poor.” This discontent stems from a career change suggested by
        Mrs. Fox. Before becoming a newspaper journalist, Mr. Fox used to steal
        livestock from local farmers Boggis, Bunce, and Bean. One day, Mr. Fox
        took Mrs. Fox on one of his excursions, and they ended up getting
        caught. Mrs. Fox reveals that she’s pregnant and tells Mr. Fox that if
        they escape and live, he has to find a new line of work — for the sake
        of their family. Mr. Fox reluctantly agrees. 2 years (12 fox years)
        later, we see Mr. Fox dealing with the effects of the midlife crisis. He
        says, “Who am I? Why a fox? Why not a horse, or a beetle, or a bald
        eagle? Who am I, and how could a fox ever be happy without a chicken in
        its teeth?”
        <br />
        <br />I think it’s interesting that Mr. Fox equates so much of who he is
        to his job. Earlier in the movie, when his realtor asks what he does for
        a living, Mr. Fox replies “I used to steal birds, but now I’m a
        newspaperman.” I <i>USED</i> to steal birds. He addresses his past first
        because to him, that’s who he thinks he should be — a fox with a chicken
        in its teeth. And I think this is a feeling that all of us are all too
        familiar with. It’s easy to feel pressured to conform, such as getting a
        fancy, “cool”, job and think that it’ll solve all our problems. And in
        no way am I saying that this is inherently wrong, but I do think it’s a
        little deceiving to chase something purely because of the worth that
        others place onto it. If other people are doing it and they seem happy,
        and look like they belong, that must mean that I’ll be happy too, right?
        Thus, it becomes almost instinctual to conform — after all, who would
        want to be different?
        <br />
        <br />
        Being different is a common theme throughout the movie, and it’s shown
        through all of the characters. But it’s the way the characters embrace
        difference where we learn the most. It’s established many times in the
        movie that Mr. Fox has a phobia of wolves. However, it’s not wolves that
        he’s scared of, it’s what they represent that he can’t bear to face. The
        wolf, is quite literally different — both metaphorically and literally.
        The wolf in nature, is wild, untamed, the exact opposite of Mr. Fox. The
        way the wolf is physically depicted in the movie is also different. The
        wolf doesn’t speak and it closely resembles an actual animal, while Mr.
        Fox is anthropomorphized. Depicted through his phobia, being different
        is everything that Mr. Fox fears in himself.
        <br />
        <br />
        Near the climax of the film, when all the animals are deep underground,
        Mr. Fox comes to an awareness — an awareness of all the damage he’s
        caused, of all his friends he hurt, purely because he was acting
        selfish. In probably the greatest monologue in the history of cinema, he
        says, “I enjoyed it, but I shouldn’t have done it. I think I have this
        thing where I need everyone to think I’m the greatest, the quote unquote
        fantastic Mr. Fox, and if they aren’t completely knocked out and dazzled
        and kind of intimated by me, I don’t feel good about myself. Foxes
        traditionally like to court danger, hunt prey, and outsmart predators…”
        Mr. Fox thinks he has this need, this instinct to “be a fox” — courting
        danger and hunting prey, thinking that if he doesn’t do these, his peers
        would value him less. This causes him to go on these dangerous
        adventures putting himself and everyone he loves at risk. But that’s not
        what makes Mr. Fox, Mr. Fox. That’s not what makes him so likeable
        either. In fact it’s the complete opposite. His charm, intelligence,
        wit, and caring qualities is what makes him such a good leader and
        father — not his recklessness or danger. He’s different, and that’s
        okay. His difference is where he thrives.
        <br />
        <br />
        Which brings us back to the wolf scene. This scene is so beautiful
        because it symbolizes Mr. Fox coming to terms with the fact that he’s
        different, and embracing it, rather than being scared of it. It’s also
        worth pointing that Ash (Mr. Fox’s son), similar to Mr. Fox was worried
        about being different the entire movie, but ended up saving the team
        only once he mustered the courage to embrace his difference. Which
        actually highlights a valuable point that being different is hard. It’s
        so much easier to conform — you don’t have to worry about being judged,
        about what other’s think of you. But you can’t expect to make change if
        you’re doing the same thing as everybody else. Difference is good.
        Difference is NEEDED in the world. If there’s one thing that{" "}
        <i>Fantastic Mr. Fox</i> teaches us is that we should embrace our
        differences and use it to inspire others. We have nothing to prove to
        anybody else except ourselves.
        <br />
        <b>
          Be different. Be you. <i>Be fantastic.</i>
        </b>
      </div>
    </div>
  );
};

export default Fox;
