import React from "react";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSelectedOption } from "./contexts/SelectedOptionContext";
import WelcomePage from "./components/WelcomePage/WelcomePage";
import Showcase from "./components/Showcase/Showcase";
import Snake from "./components/Snake/Snake";
import "./Portfolio.css";

const Portfolio = () => {
  const { showShowcase } = useSelectedOption();
  const start_button = process.env.PUBLIC_URL + "/taskbar/start_button.png";
  const start_button_hover =
    process.env.PUBLIC_URL + "/taskbar/start_button_hover.png";
  const start_button_click =
    process.env.PUBLIC_URL + "/taskbar/start_button_click.png";
  const bluetooth = process.env.PUBLIC_URL + "/taskbar/bluetooth.png";
  const computer = process.env.PUBLIC_URL + "/taskbar/computer.png";
  const arrowDevice = process.env.PUBLIC_URL + "/taskbar/arrow_device.png";
  const speaker = process.env.PUBLIC_URL + "/taskbar/speaker.png";
  const commandLine = process.env.PUBLIC_URL + "/windowsIcons/command_line.png";
  const computerIcon = process.env.PUBLIC_URL + "/icons/computer.png";
  const snakeIcon = process.env.PUBLIC_URL + "/icons/snakelogo.png";
  const shutdown = process.env.PUBLIC_URL + "/icons/shutdown.png";

  const [isStartClicked, setIsStartClicked] = useState(false);
  const [imgSrc, setImgSrc] = useState(start_button);
  const [isHovering, setIsHovering] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isDragging, setIsDragging] = useState(false);
  const [draggingIcon, setDraggingIcon] = useState(null);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [tabs, setTabs] = useState([
    {
      id: 1,
      icon: commandLine,
      title: "C:\\WELCOME\\cmd.exe",
      isFocused: true,
      isVisible: true,
      inTaskBar: true,
      component: WelcomePage,
    },
    {
      id: 2,
      icon: computerIcon,
      title: "My Portfolio",
      isFocused: false,
      isVisible: false,
      inTaskBar: false,
      component: Showcase,
    },
    {
      id: 3,
      icon: snakeIcon,
      title: "Snake",
      isFocused: false,
      isVisible: false,
      inTaskBar: false,
      component: Snake,
    },
  ]);
  const [iconPositions, setIconPositions] = useState({
    command: { x: 0, y: 0 },
    showcase: { x: 0, y: 0 },
    snake: { x: 0, y: 0 },
  });
  const [iconClicks, setIconClicks] = useState({
    command: false,
    showcase: false,
    snake: false,
  });

  const iconRef = useRef(null);
  const highestZIndexRef = useRef(1);
  const audioContextRef = useRef(null);
  const mouseDownBufferRef = useRef(null);
  const mouseUpBufferRef = useRef(null);

  const handleStartClick = () => {
    setIsStartClicked(!isStartClicked);
    setIsHovering(false);
    setImgSrc(isStartClicked ? start_button : start_button_click);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
    setImgSrc(isStartClicked ? start_button_click : start_button_hover);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setImgSrc(isStartClicked ? start_button_click : start_button);
  };

  const handleMouseUp = () => {
    setImgSrc(isHovering ? start_button_hover : start_button);
  };

  const handleFocusApplication = (id) => {
    setTabs((currentTabs) => {
      let maxZIndex = highestZIndexRef.current + 1;
      const updatedTabs = currentTabs.map((tab) => {
        if (tab.id === id) {
          highestZIndexRef.current = maxZIndex;
          return {
            ...tab,
            isFocused: true,
            isVisible: true,
            zIndex: maxZIndex,
          };
        } else {
          return { ...tab, isFocused: false };
        }
      });

      return updatedTabs;
    });
  };

  const handleApplicationClick = (id) => {
    setTabs((currentTabs) =>
      currentTabs.map((tab) =>
        tab.id === id
          ? { ...tab, isFocused: true, isVisible: true, inTaskBar: true }
          : { ...tab, isFocused: false }
      )
    );
  };

  const handleSingleClick = useCallback((iconId) => {
    setIconClicks((prev) => ({
      ...prev,
      [iconId]: !prev[iconId],
    }));
  }, []);

  const handleDoubleClick = (id) => {
    handleApplicationClick(id);
    setIconClicks((prevIconClicks) => ({
      ...Object.keys(prevIconClicks).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
    }));
  };

  const handleExitClick = (id) => {
    setTabs((currentTabs) => {
      let nextFocusIndex = null;
      const updatedTabs = currentTabs.reduce((acc, tab, index) => {
        if (tab.id === id) {
          acc.push({
            ...tab,
            isFocused: false,
            isVisible: false,
            inTaskBar: false,
          });
        } else {
          if (nextFocusIndex === null && tab.isVisible) {
            nextFocusIndex = index;
          }
          acc.push(tab);
        }
        return acc;
      }, []);

      if (nextFocusIndex !== null) {
        updatedTabs[nextFocusIndex] = {
          ...updatedTabs[nextFocusIndex],
          isFocused: true,
        };
      }

      return updatedTabs;
    });
  };

  const toggleApplicationVisibility = (id) => {
    setTabs((currentTabs) => {
      const isFocusedAndVisible = currentTabs.some(
        (tab) => tab.id === id && tab.isFocused && tab.isVisible
      );

      let nextFocusIndex = null;

      let updatedTabs = currentTabs.map((tab, index) => {
        if (tab.id === id) {
          const newVisibility = isFocusedAndVisible ? !tab.isVisible : true;
          if (!newVisibility) {
            const nextTab =
              currentTabs.slice(index + 1).find((t) => t.isVisible) ||
              currentTabs.slice(0, index).find((t) => t.isVisible);
            if (nextTab) {
              nextFocusIndex = currentTabs.indexOf(nextTab);
            }
          }
          return {
            ...tab,
            isVisible: newVisibility,
            isFocused: true,
          };
        } else {
          return { ...tab, isFocused: false };
        }
      });

      if (nextFocusIndex !== null) {
        updatedTabs[nextFocusIndex] = {
          ...updatedTabs[nextFocusIndex],
          isFocused: true,
        };
        updatedTabs.find((tab) => tab.id === id).isFocused = false;
      } else if (isFocusedAndVisible) {
        updatedTabs = updatedTabs.map((tab) => ({ ...tab, isFocused: false }));
      }

      return updatedTabs;
    });
  };

  const preventDragHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleMouseMoveDown = useCallback(
    (iconId, e) => {
      setIsDragging(true);
      setDraggingIcon(iconId);
      const currentPosition = iconPositions[iconId];
      setStartPosition({
        x: e.clientX - currentPosition.x,
        y: e.clientY - currentPosition.y,
      });
      e.preventDefault();
    },
    [iconPositions]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging && draggingIcon) {
        const newPosition = {
          x: e.clientX - startPosition.x,
          y: e.clientY - startPosition.y,
        };
        setIconPositions((prevPositions) => ({
          ...prevPositions,
          [draggingIcon]: newPosition,
        }));
      }
    },
    [isDragging, startPosition, draggingIcon]
  );

  const handleMouseMoveUp = useCallback(() => {
    setIsDragging(false);
    setDraggingIcon(null);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseMoveUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseMoveUp);
    };
  }, [isDragging, handleMouseMove, handleMouseMoveUp]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".icon")) {
        setIconClicks({
          command: false,
          showcase: false,
          snake: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showShowcase) {
      handleApplicationClick(2);
    }
  }, [showShowcase]);

  const initializeAudio = async () => {
    if (audioContextRef.current === null) {
      audioContextRef.current = new (window.AudioContext ||
        window.AudioContext)();
    }

    const audioContext = audioContextRef.current;

    const loadSound = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return audioContext.decodeAudioData(arrayBuffer);
    };

    mouseDownBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mousedown.mp3`
    );
    mouseUpBufferRef.current = await loadSound(
      `${process.env.PUBLIC_URL}/sound/mouseup.mp3`
    );
  };

  const playSound = (audioBuffer) => {
    if (audioContextRef.current && audioBuffer) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
    }
  };

  useEffect(() => {
    initializeAudio();

    const handleMouseDown = () => playSound(mouseDownBufferRef.current);
    const handleMouseUp = () => playSound(mouseUpBufferRef.current);

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      audioContextRef.current?.close();
    };
  }, []);

  return (
    <div className="App">
      <div
        className={`command-icon ${iconClicks.command ? "clicked" : ""}`}
        style={{
          left: `${iconPositions.command.x}px`,
          top: `${iconPositions.command.y}px`,
        }}
        onMouseDown={(e) => handleMouseMoveDown("command", e)}
        onClick={() => handleSingleClick("command")}
        ref={iconRef}
        onDoubleClick={() => handleDoubleClick(1)}
      >
        <img src={commandLine} alt="command icon" />
        <p>welcome.exe</p>
      </div>
      <div
        className={`showcase-icon ${iconClicks.showcase ? "clicked" : ""}`}
        style={{
          left: `${iconPositions.showcase.x}px`,
          top: `${iconPositions.showcase.y}px`,
        }}
        onMouseDown={(e) => handleMouseMoveDown("showcase", e)}
        onClick={() => handleSingleClick("showcase")}
        ref={iconRef}
        onDoubleClick={() => handleDoubleClick(2)}
      >
        <img src={computerIcon} alt="computer icon" />
        <p>My Portfolio</p>
      </div>
      <div
        className={`snake-icon ${iconClicks.snake ? "clicked" : ""}`}
        style={{
          left: `${iconPositions.snake.x}px`,
          top: `${iconPositions.snake.y}px`,
        }}
        onMouseDown={(e) => handleMouseMoveDown("snake", e)}
        onClick={() => handleSingleClick("snake")}
        ref={iconRef}
        onDoubleClick={() => handleDoubleClick(3)}
      >
        <img src={snakeIcon} alt="snake icon" />
        <p>Snake</p>
      </div>
      <div className="taskbar">
        <img
          className="start_button"
          src={imgSrc}
          alt="start button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseUp}
          onDragStart={preventDragHandler}
          onClick={handleStartClick}
        />
        {isStartClicked && (
          <div className="start-button-settings">
            {/* <div className="start-button-settings-top"></div>
            <div className="start-button-content">
              <div className="content-background">Working on it!</div>
            </div> */}
            <div className="start-button-settings-bottom">
              <a
                href="https://www.youtube.com/watch?v=vLtmmFjxSoc&ab_channel=Player-Topic"
                target="_blank"
                rel="noopener noreferrer"
                onDragStart={preventDragHandler}
              >
                <div className="shutdown">
                  <img
                    src={shutdown}
                    alt="shut down"
                    onDragStart={preventDragHandler}
                  />
                  <p>Shut Down</p>
                </div>
              </a>
            </div>
          </div>
        )}
        <div className="tabs">
          {tabs
            .filter((tab) => tab.inTaskBar)
            .map((app) => (
              <div
                key={app.id}
                className={`application-window ${app.isFocused ? "focus" : ""}`}
                onClick={() => toggleApplicationVisibility(app.id)}
                style={{ zIndex: app.isFocused ? 2 : 1 }}
              >
                <img
                  className="application-icon"
                  src={app.icon}
                  alt={app.title}
                />
                <span className="application-title">{app.title}</span>
              </div>
            ))}
        </div>
        <div className="time-bar">
          <div className="time">
            {currentTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
          <div className="bluetooth">
            <img
              src={bluetooth}
              alt="bluetooth"
              onDragStart={preventDragHandler}
            />
          </div>
          <div className="computer-icon">
            <img
              src={computer}
              alt="computer icon"
              onDragStart={preventDragHandler}
            />
          </div>
          <div className="speaker">
            <img src={speaker} alt="speaker" onDragStart={preventDragHandler} />
          </div>
          <div className="arrow-device">
            <img
              src={arrowDevice}
              alt="arrow device"
              onDragStart={preventDragHandler}
            />
          </div>
        </div>
      </div>
      {tabs.map((app, index) => (
        <div
          key={app.id}
          style={{
            position: "absolute",
            display: app.isVisible ? "block" : "none",
            zIndex: app.isFocused ? 1000 : index,
          }}
          onMouseDown={() => handleFocusApplication(app.id)}
        >
          {React.createElement(app.component, {
            app,
            isFocused: app.isFocused,
            onMinimize: () => toggleApplicationVisibility(app.id),
            onExit: () => handleExitClick(app.id),
          })}
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
