import "./Contact.css";
import { useCallback } from "react";

const Contact = () => {
  const transparentgmail =
    process.env.PUBLIC_URL + "/icons/transparentgmail.png";

  const preventDragHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <div className="contact">
      <div className="contact-title">Contact Me</div>
      <div className="contact-info">
        <div className="github">
          <a
            href="https://github.com/matia-lee"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAC4jAAAuIwF4pT92AAADN0lEQVRIiZ1VS08TURg9U01pO04f03m0JcYWN+yxhIApRDAxoLFIiD+ANCxE3NRFdSfuyoJXwB+AEMG0slAWJcGUsNCdKVL6D0ioy4YN5LjozEhty+smXyb33nO+c+fe754rkMRl2u7uLo+OjgAAmqahu7tbuBSRZNNYmJ9nbyxGn8dDADXh83jYG4txYX6e5+VoOPhxeZmtoZCVzC2KVGWZuqJQUxSqsky3KFrzt1tb+XF5uaFQ3cDExIRFDOo6A5rGgKoyoKrUja8VmsaQrlv4V5OTdSI1ncTYGAHQfuOGRVJlmUFdp6YoVHw+Kj4fNUVhUNepyrKFMzmJsTE2FFiYmyMAOux2dkWjnE6n+WRoqG7v/48nQ0OcTqfZFY3SYbcTAJcWF1knAICi00kAfD81ZQGymQxfJ5NcX1vjTj7PnXyea58+MZlM8ks2a+Hev5uimUMAagXGEwkCYGsgQAB8m0qdWxmN4k0qVZNjPJEgSdgAYHVlBS6HA+aNKJfLlyrxs+3PGY7L4cDqykq1k81kCIABVaXodFLx+6+8ejMUv5+i08mAqhIAM5kMbT9//KgqCQIqx8cYjsevvHqzDcfjqBwfA0L1khd+/YLt8PAQAMyDRjgSubaAxTVylctl2E5PTwEAprFUKpVrC/zPPTk5gU1yu2sGSwcH1xYoFYs1fUmSYGtvbwcACIIAyeVCLpe7tkBuawuSywXBOIM74TCwv79PAJQ9Htpv3iQAPh4cvHIlDT56RAAM6Tplw31/7+1VJzs7OgiAz0dH+SweJwA+HBhgPp+/UOj79jYH+vur5qhpDBrm13nvHq2bnM1mCYCjIyMkiZcvXlheEwqFODszUyc0OzPDSFvkH85wXk1RCMCyEYsQu3+fADj89ClJ4kFfn0Xe3NysE/j29SsB0CtJDBqWbtpEf19fvdmRhNfYu8/r6ySJQqFw7hbJXi/dokj9THKP293YrknioFjkLeOleptKcSuX48bGRlMR2eulV5IY0LTqyydJPCgWmwuY0RmN1nh+qVRqKCK2tFiYnp6ehpimv7+0uMhIOEyc8fY6MsC7bW38sLTUHHNRGZ53DoW9vQvL+C8tGeGu4vljygAAAABJRU5ErkJggg=="
              alt="github"
              onDragStart={preventDragHandler}
            />
          </a>
          <a
            href="https://github.com/matia-lee"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>[ GITHUB ]</p>
          </a>
        </div>
        <div className="linkedin">
          <a
            href="https://www.linkedin.com/in/matialee/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAC4jAAAuIwF4pT92AAAFHklEQVRIiYWWa2wUVRTHf/fOzO5st7tdt08olEdLoCnQAooxBGKUGhQhGFCBDyZogqLGiJCgJhiDfEA06BcNKAQkCAQV8BGID0LCS3nISygQKLW1tLTQ7tLuo/uYuX6YdreIwEkmmTnn3vO/93/+99wRtq0QApSC7Rfa0aXA7zHIz3VxtClc8tvFmzPrb0RrW8I9VaF4qkQppeW49FCJ3321LD/ncHWp/4fpVUXHXVJwK5qkM55myuA8hgVMlALxX4DKYi+XQz3+rYeb3t9b174o0ZUwEYBpgFsDgLQNPWlIWeDWGTf0gX1zHi5975mq4iPn2yLUFHipCHpuBwD443qE/Vc7H1/908Ut4euREvJM8Oig+H8TgKUgFAdN8tITFSvnTBi4fGJRLkFTvxPgnf0Nz67aemYHsneiAgpyQJdg3w0FkMLZUWeM6nEDt51+45H5ALYNMmnZAOxrCE1atf3sDnQJPjfzayuYW1sOCQtS9t2TgwPu1qEwlzPHmuct2FW3zgkopBRwI5pyPf3l8Z+xFVg2b0+t4Ovnx7Bt7liW1JZDZ+zeAOAUURdQ4mPTd+cXbjlzfaaUAmlokmW/XP64pzHspdALiTRTyoOZeY+OyHeKeQ+GsiA4QsgxeHV33aZbibTQZi1aEnhlx7mduHVwSRCCo9e6qBrgo74zxuvf1hFKWSAEWLZDh65BIg2xlEOfLkHKLIjXRbIp7PHk5zSLt/ZeennNN+fXUux1goaEmzEyle8rdDzlvAsc1QQ9DC3w0p1M0/FPF5g6+NzOIgTQEaeysvCofqghPB1NZClojTC3tpzFk4YA8OGBv9m55xKbl06meoCPw40h9vzVxkczRjGq0EssZfH9+XZe2HKGdHcCvIaTK+jhQmt3tV7fEavC68rymLAYGvAwsdQPQFmeG2IpJg8JMDRgMrbIy6KHBmWG5xga82oG0B5P8+YXx8BjODsQAlBKdkSThZh6FkATdCfSmc9IwgJd0tqdyPgaQnFe23CCjSdbMr7nRhdBgReSVlZVUqQlSmkZvu9qt8enbTzJ55tP8+L6EzTd6gEg16Xj8bqcGvQz6XbpXaSs+wBkrSdt0xSOw+hi0CTNvQBSgHHnOoUcFvTUE0neJ232EFg2mLp0KNAkRq88baVQ/c+KFJCyTflgqf8AiTT3pikbEwKkyKrurrMiSfIDZrOcVVW0FVOHdF9xehNkFuL0cl06PkOTt+0q6+8HJQV0xnlyZMFuOauy6FxNVfFx2qNOQCk8hsyMNXUJliLgMZxE0hmG7WD0+U29n1aiSQh6WDp5yBqhlOJIU3jMpBX7z+JzgyYoLfAysiAHgAs3orS2RRlfESRg6qRtxe9NYVIJC6RgwuA88kydlK040hjGsmxoDLNwfvWn62aMWix6kmnchsayX6+sWL3p1HKGBJy20Kd7n9t5bkSdpicEBD1O/1E4bSPlgFGQA21RyiqCDVeWTRluSIGwLNvp2cBjG/7cun9f/TxK/WBo3C6L+5gQ0B6BXFeo8YOpo8t87hbbBtm/oGtmVc5/qrZ8LZ1xCMd7j/x9EkvhdNaWLiqGB+s+WzC+psznbunDzF6ZCg5e6yLf52Lz8eZ5Xx1sXHm9pXs4Lg38bgeo/4aEyFLpN5lZU/LJu9NGLEmmbFXiMRhxx6Wv4Mf6DgxNEsx10RZJyl2nWhcequ+YfbUjNsFS6Mh+LdxW5HuNpnGD8vbOHjdw/YSyvEtWyuJKR4xJg7K/Lf8CgS4e1hgqQpsAAAAASUVORK5CYII="
              alt="linkedin"
              onDragStart={preventDragHandler}
            />
          </a>
          <a
            href="https://www.linkedin.com/in/matialee/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>[ LINKEDIN ]</p>
          </a>
        </div>
        <div className="email">
          <a href="mailto:matialee9@gmail.com">
            <img
              src={transparentgmail}
              alt="gmail"
              onDragStart={preventDragHandler}
            />
          </a>
          <a href="mailto:matialee9@gmail.com">
            <p>[ EMAIL ]</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
