import "./MovieBlog.css";

const MovieBlog = ({ onOptionChange }) => {
  const openHerAnalysis = () => {
    onOptionChange("Her");
  };

  const openFoxAnalysis = () => {
    onOptionChange("Fox");
  };

  const openMissSunshineAnalysis = () => {
    onOptionChange("MissSunshine");
  };

  return (
    <div className="movie-blog">
      <div className="movie-blog-title">Movie Blog</div>
      <div className="movie-container">
        <div className="movie">
          <img
            src="https://lh6.googleusercontent.com/proxy/p2SClkPnSd9o5QpnNmvTTkVOaep6srY1vkZLsMRDw6DrkZJvPFfZw6cH8q54WZiyXKSoVB_YGCDKQMJuQd7nMGbVGGE"
            alt="her poster"
            onClick={openHerAnalysis}
          />
          <p>
            <b>
              Love, acceptance, and the meaning of life in <i>Her</i>
            </b>
          </p>
        </div>
        <div className="movie">
          <img
            src="https://www.tallengestore.com/cdn/shop/products/FantasticMrFox-GeorgeClooney-WesAnderson-HollywoodMoviePoster_d2eb09b7-79a9-4bae-a7a6-8706017a5800.jpg?v=1602760202"
            alt="Mr Fox poster"
            onClick={openFoxAnalysis}
          />
          <p>
            <b>
              <i>Fantastic Mr. Fox</i>: How to be FANTASTIC
            </b>
          </p>
        </div>
        <div className="movie">
          <img
            src="https://image.tmdb.org/t/p/original/wKn7AJw730emlmzLSmJtzquwaeW.jpg"
            alt="Little Miss Sunshine poster"
            onClick={openMissSunshineAnalysis}
          />
          <p>
            <b>
              <i>Little Miss Sunshine</i>: Winners and Losers
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MovieBlog;
